/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"

import "../styles/components/layout.css"

const Layout = (props) => {
  return (
    <>
      <Header location={ props.location } />
      <main>{ props.children }</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  location: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Layout
