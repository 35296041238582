import React from "react"
import { Link } from "gatsby"

import { IconContext } from "react-icons"
import { FaEnvelope, FaPhoneAlt, FaFacebookSquare, FaInstagram, FaTwitter } from "react-icons/fa"

import footerStyles from "../styles/components/footer.module.css"

const Footer = () => (
  <footer>
    <div className={ footerStyles.ourLocation }>
      <h5>Our Office</h5>
      <p><strong>TEAMPLAY</strong></p>
      <p>The IMC Block, 3rd Floor</p>
      <p>141 Scout Rallos Extension</p>
      <p>Sacred Heart, Quezon City, Metro Manila 1103 PH</p>
      <div className={ footerStyles.email }>
        <IconContext.Provider value={{ size: "1em", color: "#F57F20" }}>
          <FaEnvelope />
        </IconContext.Provider>
        <p>teamplayexec@yahoo.com</p>
      </div>
      <div className={ footerStyles.trunkLine }>
        <IconContext.Provider value={{ size: "1em", color: "#F57F20" }}>
          <FaPhoneAlt />
        </IconContext.Provider>
        <p>(02) 3448-5386</p>
      </div>
    </div>
    <div className={ footerStyles.footerNav }>
      <h5>Navigation</h5>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about/">About</Link></li>
        <li><Link to="/services/">Services</Link></li>
        <li><Link to="/news/">News</Link></li>
        <li><Link to="/contact/">Contact Us</Link></li>
      </ul>
    </div>
    <div className={ footerStyles.socialMedia }>
      <h5>Social Media</h5>
      <ul>
        <li>
          <IconContext.Provider value={{ size: "1.25em", color: "#F57F20" }}>
            <FaFacebookSquare />
          </IconContext.Provider>
          <a href="https://www.facebook.com/TEAMPLAYPH/">Facebook</a>
        </li>
        <li>
          <IconContext.Provider value={{ size: "1.25em", color: "#F57F20" }}>
            <FaTwitter />
          </IconContext.Provider>
          <a href="https://twitter.com/TeamPlayPH">Twitter</a>
        </li>
        <li>
          <IconContext.Provider value={{ size: "1.25em", color: "#F57F20" }}>
            <FaInstagram />
          </IconContext.Provider>
          <a href="https://www.instagram.com/team_playph/">Instagram</a>
        </li>
      </ul>
    </div>
    <p className={ footerStyles.copyrightText }>
      Copyright © 2020, TEAMPLAY. All Rights Reserved.
    </p>
  </footer>
)

export default Footer