import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import headerStyles from "../styles/components/header.module.css"

const Header = (props) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "teamplay-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <header>
      <div className={ headerStyles.logo }>
        <Link to="/">
          <Img fluid={data.placeholderImage.childImageSharp.fluid} />
        </Link>
      </div>
      <input type="checkbox" className={ headerStyles.toggle } id="navToggle" />
      <nav>
        <ul>
          <li className={ (props.location == "home") ? headerStyles.active : "" }>
            <Link to="/">Home</Link>
          </li>
          <li className={ (props.location == "about") ? headerStyles.active : "" }>
            <Link to="/about/">About</Link>
          </li>
          <li className={ (props.location == "services") ? headerStyles.active : "" }>
            <Link to="/services/">Services</Link>
          </li>
          <li className={ (props.location == "news") ? headerStyles.active : "" }>
            <Link to="/news/">News</Link>
          </li>
          <li className={ (props.location == "contact") ? headerStyles.active : "" }>
            <Link to="/contact/">Contact Us</Link>
          </li>
        </ul>
      </nav>
      <label htmlFor="navToggle" className={ headerStyles.toggleLabel }>
        <span className={ headerStyles.toggleSymbol }></span>
      </label>
    </header>
  )
}

Header.propTypes = {
  location: PropTypes.string.isRequired
}

export default Header